<template>
    <div>
      <el-container>
        <CommonTree
          ref="commonTree"
          treeTitle="项目架构"
          :treeData="treeData"
          :defaultProps="treeProps"
          :showCheckbox="false"
          @getNodeClick="handleNode"
          node-key="id"
        >
        </CommonTree>
        <el-main>
          <head-layout
            :showIcon="false"
            head-title="人员管理"
          ></head-layout>
          <grid-head-layout
            ref="organizationPersonnelGridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="organizationPersonnelGridHeadSearch"
            @grid-head-empty="organizationPersonnelGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            :page="page"
            :data-total="page.total"
            @page-current-change="getPrjstructureuserList"
            @page-size-change="getPrjstructureuserList"
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="organizationPersonnelTableData"
            :table-loading="tableLoading"
            @gird-handle-select-click="selectionChange"
            class="itemListGridNoPage"
          >
            <template slot="baseCode" slot-scope="{ row }">
              <el-image :preview-src-list="[formatter(row)]" style="padding-top:10px;width: 40px;"
                        :src="formatter(row)"></el-image>
            </template>
            <template slot="isUsed" slot-scope="{row}">
              <el-tag effect="dark" :type="isUsedDict[row.isUsed]">{{ row.$isUsed }}</el-tag>
            </template>
            <template #customBtn="{row}">
              <el-button type="text" @click="rowChange(row)">选择</el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      
    </div>
  </template>
  <script>
  import {
    prjthirduserPage, prjthirduserDetail, prjthirduserSubmit,
    prjthirduserRemove, prjthirdcompanyRemove, userCountByType
  } from "@/api/safe/itemList";
  import {dateFormat} from "@/util/date";
//   import addOrganizationPersonnel from "./addOrganizationPersonnel";
  // import userSelect from "../components/user-select";
  import CommonTree from "@/views/components/com_tree/index";
  import GridLayout from "@/views/components/layout/grid-layout";
  import HeadLayout from "@/views/components/layout/head-layout";
//   import ResponsibilityDrawer from "./components/ResponsibilityDrawer.vue";
  import {getDeptTree, remove} from "@/api/system/dept";
  import {
    prjthirduserBatchSubmit
  } from "@/api/safe/itemList";
  import {roleGetList} from "@/api/system/user";
//   import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {mapGetters} from "vuex";
  import {exportBlob} from "@/api/common";
  import {downloadXls} from "@/util/util";
  
  export default {
    mixins: [organization],
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    components: {
      CommonTree,
      GridLayout,
      HeadLayout,
    //   ResponsibilityDrawer,
    },
    name: "organizationPersonnel",
    data() {
      return {
        isUsedDict: {
          '0': 'success',
          '1': 'info',
          '2': '',
          '3': 'danger',
        },
        selectionList: [],
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        treeNode: "",
        treeData: [],
        filterData: [],
        treeProps: {
          children: "children",
          label: "title",
        },
        searchColumns: [
          {prop: "userName", span: 3, placeholder: "请输入人员姓名"},
          {prop: "phone", span: 3, placeholder: "请输入联系电话"},
          {
            prop: "isUsed", span: 3,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: 'select',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_user_status",
  
            placeholder: "入场状态"
          },
        ],
        tableOptions: {
          index: true,
          indexLabel: "序号",
          // menuWidth: 180,
          selection: false,
          column: [
            {
              label: "姓名",
              prop: "userName",
              align: "center",
              minWidth: 120,
              overHidden: true,
            },
            {
              label: '人员类别',
              align: "center",
              prop: 'thirdFlg',
              html: true,
              formatter: (row) => {
                return `<span style="color: ${row.thirdFlg == '1' ? 'red' : '#333333'}">${row.thirdFlg == '1' ? '外部人员' : '内部人员'}</span>`
              },
            },
            {
              label: "性别",
              prop: "sex",
              minWidth: 80,
              align: "center",
              dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl:
                "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
            },
            {
              label: "年龄",
              prop: "birthday",
              minWidth: 80,
              align: "center",
              overHidden: true,
              html: true,
              formatter: (row) => {
                let style = this.itemObject(row)
                return `<span style="color:${style.color}">${this.getAge(row.birthday)}</span>`
              },
            },
            {
              label: "联系电话",
              prop: "phone",
              align: "center",
              minWidth: 180,
              overHidden: true,
            },
            {
              label: "岗位",
              prop: "stPostName",
              width: 150,
              align: "center",
              overHidden: true
            },
            {
              label: "状态",
              slot: true,
              prop: "isUsed",
              dataType: "number",
              minWidth: 80,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
  
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_user_status",
              align: "center",
            },
            {
              label: "更新时间",
              prop: "updateTime",
              minWidth: 170,
              align: "center",
              overHidden: true,
            },
          ],
        },
        organizationPersonnelTableData: [],
        tableLoading: false,
      };
    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      excelOption(){
        return  {
          submitBtn: false,
          emptyBtn: false,
          column: [
            {
              label: this.$t("cip.plat.sys.user.field.excelFile"),
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              data: {prjId: this.prjId, deptId: this.treeNode.id},
              action: `/api/sinoma-hse-prj/prjthirduser/import`,
            },
            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        }
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      itemObject(item) {
        let age = this.getAge(item.birthday) || 0
        if (
          (item.sex == 1 && age >= 50) ||
          age >= 60 || age < 18
        ) {
          // (item.sex == 2 && age >= 18 && age < 60)
          return {color: "red"};
        } else {
          return {color: "black"};
        }
      },
      // 初始化 init
      init() {
        this.selectionList = [];
        this.getPrjstructureList(this.prjId);
      },
      //项目组织架构
      getPrjstructureList(parentId) {
        getDeptTree('', '', parentId).then((res) => {
          if ((res.data.code = 200)) {
            this.treeData = [
              {
                ...res.data.data[0],
                parentId: -1,
                children: res.data.data[0]['children']
              }
            ]
            if (res.data.data.length > 0) {
              this.filterData = this.treeData[0].children?.filter((item) => item.thirdFlg != 1) || []
              this.$refs.commonTree.setCurrentKey(this.treeData[0].id)
              this.$refs.commonTree.nodeClick(this.treeData[0], {})
            }
          }
        });
      },
      //项目组织架构树点击
      handleNode(data, node) {
        this.treeNode = data;
        this.page.currentPage = 1;
        this.getPrjstructureuserList();
      },
      
      // //批量选择
      // selectionChange(list) {
      //   this.selectionList = list;
      //   if (!this.isIndex) {
      //     // 项目角色授权 选择人员 弹框进来
      //     this.$emit("selectOPList", this.selectionList);
      //   }
      // },
      
      // 组织人员列表筛选条件
      // 搜索
      organizationPersonnelGridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.getPrjstructureuserList(this.page, searchForm);
      },
      // 清空
      organizationPersonnelGridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.getPrjstructureuserList(this.page, {});
      },
      removeEmptyValues(obj) {
        for (const key in obj) {
          if (!obj[key]) {
            delete obj[key];
          } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
            delete obj[key];
          }
        }
        return obj;
      },
      
      //项目组织架构人员
      getPrjstructureuserList(page, params = {}) {
        this.page = page || this.page;
        let obj = {
          prjId: this.prjId,
          deptId: this.treeNode.id,
          current: this.page.currentPage,
          size: this.page.pageSize,
          ...params,
        };
        // 项目角色授权新增人员时候需要排除已绑定过的人
        if (!this.isIndex) {
          obj.excludeRoleId = this.roleId;
        }
        this.tableLoading = true
        prjthirduserPage(this.removeEmptyValues(obj)).then((res) => {
          this.organizationPersonnelTableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          this.tableLoading = false
        });
      },

      rowChange (row) {
        this.$emit('callback', row)
      }
      
    },
  };
  </script>
  <style lang="scss" scoped>
  /* ::v-deep .itemListGridNoPage .avue-crud .el-table {
    height: calc(100vh - 450px) !important;
    max-height: calc(100vh - 450px) !important;
    box-sizing: border-box;
  }
  
  .header-box {
    margin-top: 5px;
    margin-bottom: 5px;
  
    .box-card {
      background: rgb(25, 144, 255);
      height: 100px;
      font-size: 18px;
      color: #FFFFFF;
      width: calc(25% - 20px);
  
      .margin-t-10 {
        margin-top: 10px;
      }
    }
  }
  
  .shuttleBackBox {
    width: 100%;
    justify-content: space-between;
  
    .table-box {
      width: calc(100% - 280px);
    }
  } */
  </style>
  